import { css } from '@emotion/react';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { Breakpoint, Color, FontHeadingSize } from '@pafcloud/style';

const underlineBottom = $buildEnv.site === 'speedybet.es' ? -8 : 0;

export const PrimaryBrandHeading = css({
  position: 'relative',
  zIndex: 1,
  display: 'inline-block',

  fontSize: FontHeadingSize.Small,
  fontWeight: 900,
  fontStyle: 'italic',
  lineHeight: 1,
  textTransform: 'uppercase',
  textOverflow: 'ellipsis',

  whiteSpace: 'nowrap',

  [Breakpoint.TabletOrLarger]: {
    fontSize: FontHeadingSize.Normal,
  },

  // underline effect
  '::after': {
    content: '""',
    position: 'absolute',
    bottom: underlineBottom,
    zIndex: -1,
    display: 'block',
    width: '100%',
    height: 8,
    marginLeft: '0.25em',
    transform: 'skewX(-11deg)',
    backgroundColor: Color.Primitive.Primary,
  },
});
